import React from 'react'
import Layout from '../layouts'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import CommonHero from '../components/commonhero/commonhero.js'

class BuyPage extends React.Component {
  render() {
    return (
      <Layout
        head={{
          title: 'Serving Our Military',
        }}
      >
        <div className="military-wrap">
          <CommonHero
            pageTitle="Serving Our Military"
            custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1555485202/brianboals/military.jpg"
          />
        </div>
        <main className="bg-white">
          <div className="container military-page py-5">
            <LazyLoadImage
              className="mb-4 img-fluid"
              effect="blur"
              src="https://res.cloudinary.com/brian-boals/image/upload/c_scale,w_1170/v1559524813/brianboals/flag.jpg"
            />
            <p>
              At the Brian Boals Team we love working with and for our service
              members. We can meet any military discount out there, so please
              don't hesitate to give us a call! Buying? With over 20 years
              experience and a Colorado native, Brian has a vast knowledge of
              the area and can help you narrow down the best area of town for
              your home search. See a home you'd like to tour but you're out of
              town? Our team will give you a guided video tour of the home!
              Selling? With over 20 years experience Brian knows how to get your
              home sold! We know military families are often working on a
              timeline that is out of their control and we go out of our way to
              make this process as easy as possible within those constraints.{' '}
            </p>
            <p>Please let us serve you today!</p>
            <div class="intro text-center mt-5">
              <h5 class="subtitle text-uppercase">Military</h5>
              <h2>What Some Of Our Military Clients Are Saying</h2>
            </div>
            <div className="military-reviews mb-5 mt-2">
              <div className="military-item-tex">
                <p>
                  Robert and Brian were phenomenal to work with as a first time
                  home buyer and as a military service member who was not local
                  to the area. I found them through the Dave Ramsey ELP network.
                  Brian and Robert took the time to explained all of the ins and
                  out; earnest money, loan options, lenders, and home inspection
                  recommendations ($150 sewer scope vs the $15,000 sewer repair
                  bill) of the home buying process for me as a first time home
                  buyer. I was never in CO during my home buying process and
                  Robert facetimed me roughly 30 properties before we found the
                  one. I am so absolutely happy with my experience and it was an
                  awesome experience!
                </p>
                <h6 class="text-danger">—CPT James Di Carlo—</h6>
              </div>
              <div className="military-item-tex">
                <p>
                  I wished I knew Robert before my wife and I PCSed to Colorado
                  Springs and we foolishly rented before we bought our current
                  house. Any current military members if you are heading in this
                  area and you are looking to buy (as I wished I did before we
                  rented), please contact Robert and he will help you with any
                  concerns you may be experiencing. He was very patient with us
                  and we looked over many houses until we found the one that
                  suited our needs. Any questions please feel free to contact me
                  as we are 100% satisfied!
                </p>
                <h6 class="text-danger">—SFC Chris Steiner—</h6>
              </div>
              <div className="military-item-tex">
                <p>
                  My experience with Robert Crawford and his team had been
                  nothing short of excellent. He made the entire process smooth
                  and I can honestly say I would refer anyone to him.
                  Furthermore, with me been a disabled veteran he knows exactly
                  how to accommodate military families. He was patient and made
                  sure that we were happy and 100% fulfilled on what we
                  purchased. I look forward to referring others and buying again
                  one day soon with him.
                </p>
                <h6 class="text-danger">—Air Force (Vet) Lortavius Smith—</h6>
              </div>
              <div className="military-item-tex">
                <p>
                  Robert was very thorough in marketing my home, setting the
                  right asking price in a competitive market and attending to
                  the minor details. I’ll never forget, he’d go to my home and
                  let my dog out so that showings would not be an inconvenience
                  to potential buyers. We were under contract in less than 30
                  days at full asking price.
                </p>
                <h6 class="text-danger">—CW4 (ret) Rob Goldsmith—</h6>
              </div>
              <div className="military-item-tex">
                <p>
                  Robert has been the perfect realtor since day 1, showing me
                  places I had to see even though he knew I wouldn’t likely buy
                  them; helped me get some things out of my system I guess.
                  Always helpful and willing, and not just words either. He
                  really worked for us and helped in ways not typical for a
                  realtor. First time for us to have a water well, solar and
                  other things and he did a bunch of leg work to help us
                  understand what was what. He advised us and effectively
                  negotiated on our behalf with sellers and their agent; helped
                  get us where we wanted to be. A true Veteran’s friend, just
                  outstanding, highly recommended!
                </p>
                <h6 class="text-danger">—Vic Bentley, Veteran USAF—</h6>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default BuyPage
